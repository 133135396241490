import {configize} from '../utils';
import menus from './menus';
import routes from './routes';
import themes from './themes';
import translations from './translations';

export default configize({
    persistKey: 'app',
    requiredRole: ['contributor', 'moderator', 'admin'],
    theme: 'theme1',
    defaultLanguage: 'fr',
    features: {
        localeSwitcher: false,
    },
    menus,
    routes,
    themes,
    translations,
})