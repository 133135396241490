import React from 'react';
import 'typeface-roboto';
import config from '../configs';
import {PersistGate} from 'redux-persist/integration/react'
import {ErrorBoundary} from '@ohoareau/react-error-boundary';
import {ThemeProvider} from '@material-ui/core/styles';
import {ApolloProvider} from '@apollo/react-hooks';
import {ConfirmProvider} from 'material-ui-confirm';
import {ConnectedRouter} from 'connected-react-router';
import {Provider} from 'react-redux';
import {Switch} from 'react-router';
import {client, store, onBeforeLift, persistor, history, muiTheme} from '../context';
import PrivateRoute from "./misc/PrivateRoute";
import PublicRoute from "./misc/PublicRoute";

const App = () => (
    <ErrorBoundary metadata={{location: 'provider'}}>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <PersistGate loading={null} onBeforeLift={onBeforeLift} persistor={persistor}>
            <ConnectedRouter history={history}>
              <ThemeProvider theme={muiTheme}>
                <ConfirmProvider>
                  <Switch>
                      {config.routes.map(({name, secured, ...props}, key) => secured
                        ? <PrivateRoute key={name || key} {...props} />
                        : <PublicRoute key={name || key} {...props} />
                      )}
                  </Switch>
                </ConfirmProvider>
              </ThemeProvider>
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      </ApolloProvider>
    </ErrorBoundary>
);

export default App