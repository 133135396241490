export default {
    app_name: 'Admin',
    app_main_title: 'MAARS',
    home_welcome: 'Bienvenue sur MAARS Admin',
    help_welcome: "Pour avoir de l'aide dans l'utilisation de MAARS Admin",
    help_widget: "Obtenir de l'aide sur la configuration d'un widget",
    login_form_title: 'Connexion',
    login_form_description: 'Merci de saisir vos identifiants pour vous connecter',
    login_form_action_label: 'Connexion',
    new_password_form_title: 'Changement de mot de passe requis',
    new_password_form_description: 'Votre précédent mot de passe a expiré, merci de saisir un nouveau mot de passe pour vous connecter.',
    password_reset_form_title: 'Changement de mot de passe requis',
    password_reset_form_description: 'Une demande de changement de mot de passe a été initié pour votre compte, vous avez dû recevoir, par email, un code de sécurité à saisir ci-dessous en plus de votre nouveau mot de passe.',
    constraints_required: 'obligatoire',
    error_validation: 'Merci de vérifier votre formulaire, un ou plusieurs champs ne sont pas valides',
    error_generic: "Une erreur inattendue est survenue, merci de contacter l'administrateur (erreur: {{message}})",
    error_forbidden: 'Identifiants non reconnus ou accès non autorisé',
    errors_unknown_query: "Requêtes GraphQL '{{query}}' inconnue (disponible: {{queryNames}})",
    role_user_label: 'Utilisateur',
    role_contributor_label: 'Contributeur',
    role_moderator_label: 'Modérateur',
    role_admin_label: 'Administrateur',
    buttons_notavailable_label: 'Non disponible',
    buttons_create_label: 'Créer',
    buttons_new_label: 'Ajouter',
    buttons_add_label: 'Créer',
    buttons_edit_label: 'Modifier',
    buttons_save_label: 'Enregistrer',
    buttons_delete_label: 'Supprimer',
    buttons_execute_label: 'Executer',
    buttons_display_label: 'Afficher',
    buttons_archive_label: 'Archiver',
    buttons_reopen_label: 'Ré-ouvrir',
    buttons_login_label: 'Connexion',
    buttons_cancel_label: 'Annuler',
    buttons_submit_label: 'Soumettre',
    buttons_change_label: 'Modifier',
    buttons_next_label: 'Suivant',
    buttons_back_label: 'Précédent',
    buttons_reset_label: 'Annuler',
    buttons_finish_label: 'Valider',
    buttons_settings_label: 'Paramètres',
    buttons_moveup_label: 'Monter',
    buttons_movedown_label: 'Descendre',
    buttons_duplicate_label: 'Dupliquer',
    buttons_collapseall_label: 'Tout replier',
    buttons_expandall_label: 'Tout déplier',
    buttons_import_label: 'Importer',
    buttons_menu_label: 'Menu',
    buttons_clone_label: 'Dupliquer',
    buttons_lock_label: 'Verrouiller',
    buttons_unlock_label: 'Déverrouiller',
    buttons_propose_label: 'Ajouter',
    buttons_display_associated_content_label: 'Afficher le contenu associé',
    buttons_new_content_label: 'Ajouter',
    buttons_new_and_propose_content_label: 'Proposer',
    buttons_propose_big_label: 'Proposer un contenu',
    buttons_propose_big_label_short: 'Proposer',
    buttons_get_help_label: 'Contacter yaniss@maars.cc',
    buttons_get_help_label_short: 'Contacter',
    errors_auth_generic: 'Erreur de type {{type}} lors de l\'authentification',
    errors_auth_bad_credentials: 'Mot de passe incorrect pour {{username}}',
    errors_auth_unknown_user: 'Aucun compte utilisateur pour {{username}}',
    errors_auth_empty_user_context_for_new_password_completion: 'Votre requête de changement de mot de passe a expiré, merci de ré-itérer votre demande',
    errors_auth_empty_username_context_for_password_reset_completion: 'Votre requête de remise à zéro de votre mot de passe a expiré, merci de ré-itérer votre demande',
    errors_auth_missing_attributes_for_new_password_completion: 'Votre requête de changement de mot de passe a expiré, la confirmation n\'est plus possible, merci de ré-itérer votre demande',
    errors_auth_new_password_required: 'Vous devez changer le mot de passe pour {{username}}',
    errors_auth_password_policy_restriction: 'Le mot de passe saisi ne respecte pas les contraintes imposées par la politique de restriction pour les mots de passe imposée pour {{username}}{{description}}',
    errors_auth_password_reset_required: 'Une remise à zéro du mot de passe est nécessaire pour {{username}}',
    errors_auth_code_mismatch: 'Le code sécurité transmis n\'est pas le bon',
    errors_auth_limit_exceeded: 'Le nombre de tentative erronée maximum a été atteint, merci de contacter votre administrateur',
    errors_missing_groups: 'Vous ne disposez pas des autorisations nécessaires pour vous connecter sur cette application (vous n\'êtes pas {{missingGroups}})',
    page_not_found_title: 'Page introuvable',
    page_denied_title: 'Accès non autorisé',
    page_unauthorized_title: 'Accès non autorisé',
    layouts_standard_draw_open_label: 'Ouvrir le volet',
    usermenu_logout_label: 'Déconnexion',
    usermenu_profile_label: 'Mon compte',
    languagemenu_en_label: 'English',
    languagemenu_fr_label: 'Français',
    loading_title: 'Chargement en cours...',
    page_not_found: 'Page introuvable :(',
    page_denied: 'Accès non autorisé.',
    dropzone_input_content: "Glissez/déposez votre fichier ici ou cliquez",
    dropzone_input_content_multiple: "Glissez/déposez vos fichiers ici ou cliquez",
    dropzone_input_content_with_files: "Ajouter des fichiers",
    dropzone_submit_button_content: "Envoyer",
    constraints_count_not_a_number: "doit être un nombre",
    constraints_count_not_positive: "doit être supérieur à 0",
    constraints_count_too_high: "doit être inférieur à {{max}}",
    constraints_count_not_integer: "doit être un nombre entier",
    constraints_email: "email invalide",
    constraints_maxlength: "{{max}} caractères maximum",
    constraints_number: "doit être un nombre",
    constraints_password: "mot de passe non valide (8 caractères minimum, des chiffres, un caractère spécial, des majuscules, des minuscules)",
    constraints_security_code: "code de sécurité non valide (6 chiffres requis)",
    constraints_zip_code: "code postal non valide (5 chiffres requis)",
    constraints_phone: "numéro de téléphone invalide (+331... ou 01.... ou (+33)1....)",
    constraints_fax: "numéro de fax invalide (+331... ou 01.... ou (+33)1....)",
    constraints_mobilePhone: "numéro de téléphone mobile invalide (+336... ou 06.... ou (+33)6....)",
    constraints_url: "adresse internet non valide (doit commencer par http ou https)",
    constraints_maxitems: "maximum {{max}} {{itemTypePluralName}}",
    constraints_json: "erreur de syntaxe JSON",
    constraints_isbn: "ISBN invalide (10 ou 13 chiffres, https://en.wikipedia.org/wiki/International_Standard_Book_Number)",
    constraints_visanumber: "N° VISA invalide (chiffres uniquement)",
    item_type_tag: 'tag',
    item_type_tag_plural: 'tags',
    field_tags_label: "Tags",
    field_tags_desc: "de 1 à 10 maximum",
    field_type_label: "Type",
    field_content_type_label: "Format",
    field_title_label: "Titre",
    field_language_label: "Langue",
    field_categories_label: "Pilier",
    field_categories_label_sub: "Catégories",
    field_role_label: "Rôle",
    field_description_label: "Description",
    field_file_label: "Fichier",
    field_code_label: "Code",
    field_status_label: "Statut",
    field_cedex_label: "Cedex",
    field_cedex_placeholder: "Ex: Cedex 15",
    field_city_label: "Ville",
    field_city_placeholder: "Ex: Colombel",
    field_complement_label: "Complément",
    field_complement_placeholder: "",
    field_count_label: "Nombre",
    field_count_placeholder: "Ex: 3",
    field_country_label: "Pays",
    field_country_placeholder: "Ex: France",
    field_date_label: "Date",
    field_date_placeholder: "",
    field_email_label: "Email",
    field_email_placeholder: "marie.dupont@monentreprise.fr",
    field_firstname_label: "Prénom",
    field_firstname_placeholder: "Marie",
    field_lastname_label: "Nom de famille",
    field_lastname_placeholder: "Dupont",
    field_name_label: "Nom",
    field_name_placeholder: "Nom",
    field_number_label: "Nombre",
    field_number_placeholder: "Ex: 1",
    field_password_label: "Mot de passe",
    field_password_placeholder: "Votre mot de passe personnel ici",
    field_phone_label: "Téléphone",
    field_phone_placeholder: "Ex: 0198765432",
    field_mobilePhone_label: "Téléphone Mobile",
    field_mobilePhone_placeholder: "Ex: 0612345678",
    field_securitycode_label: "Code de sécurité",
    field_securitycode_placeholder: "123456",
    field_reference_label: "Référence",
    field_reference_placeholder: "Ex: XC-123-A",
    field_siren_label: "SIREN",
    field_siren_placeholder: "Ex: 509703625",
    field_siret_label: "SIRET",
    field_siret_placeholder: "Ex: 50970362500020",
    field_street_label: "Adresse",
    field_street_placeholder: "Ex: 147, rue d'en Haut",
    field_text_label: "Titre",
    field_text_placeholder: "",
    field_username_label: "Email",
    field_username_placeholder: "marie.dupont@monentreprise.fr",
    field_zipcode_label: "Code postal",
    field_zipcode_placeholder: "Ex: 58350",
    field_linkedIn_label: "LinkedIn",
    field_website_label: "Site internet",
    field_url_label: "Adresse internet",
    field_content_url_label: "URL du contenu",
    field_comment_label: "Commentaire",
    field_linkedIn_placeholder: "Ex: https://linkedin.com/...",
    field_website_placeholder: "Ex: https://www.monentreprise.fr/...",
    field_comment_placeholder: "Ex: ...",
    field_accessmode_label: "Accessibilité",
    field_publishingdate_label: "Date de la première publication du contenu",
    field_isbn_label: "ISBN",
    field_visanumber_label: "Numéro de Visa",
    field_widget_definition_label: "Définition du Widget",
    field_form_definition_label: "Définition du formulaire",
    menu_items_home: 'Tableau de Bord',
    menu_items_users: 'Utilisateurs',
    menu_items_contents: 'Contenus',
    menu_items_proposals: 'Propositions',
    menu_items_categories: 'Catégories',
    menu_items_tags: 'Tags',
    menu_items_types: 'Types de contenus',
    menu_items_providers: 'Plateformes',
    menu_items_languages: 'Langues',
    menu_items_sources: 'Sources',
    menu_items_masterclasses: 'Master Classes',
    menu_items_challenges: 'Challenges',
    menu_items_accessmodes: "Modes d'accès",
    menu_items_help: "Besoin d'aide ?",
    menu_items_widgets: "Widgets",
    menu_items_skills: "Compétences",
    menu_items_versions: "Versions de contenus",
    menu_items_forms: "Formulaires",
    menu_items_formSubmission: "Réponses aux formulaires",
    menu_items_adminHome: "Administration",
    menu_items_backHome: "Retour",
    panel_new_content_title: 'Ajouter un contenu',
    panel_new_content_text: "Vous pouvez ajouter votre contenu et le soumettre directement à l'équipe de modérateur ou bien choisir de le soumettre dans un second temps. Vous pourrez modifier les informations sur votre contenu tant qu'il n'aura pas été validé définitivement.",
    panel_new_proposal_title: 'Proposer un contenu',
    panel_new_proposal_text: "Merci de compléter tous les champs ci-dessous pour soumettre votre contenu. Celui-ci sera ensuite validé par l'équipe de modération qui reviendra vers vous en cas de besoin.",
    tabs_infochallenge_label: 'Informations',
    tabs_memberschallenge_label: 'Membres',
    tabs_coachschallenge_label: 'Coachs',
    tabs_guestschallenge_label: 'Invités',
    tabs_badgeschallenge_label: 'Badges',
    tabs_infomasterclass_label: 'Informations',
    tabs_membersmasterclass_label: 'Membres',
    tabs_coachsmasterclass_label: 'Coachs',
    tabs_guestsmasterclass_label: 'Invités',
    tabs_badgesmasterclass_label: 'Badges',
    tabs_infouser_label: 'Informations',
    tabs_skillsuser_label: 'Compétences',
    tabs_allcontents_label: 'Tous',
    tabs_draftcontents_label: 'A soumettre',
    tabs_refusedcontents_label: 'Réfusés',
    tabs_acceptedcontents_label: 'Acceptés',
    tabs_submittedcontents_label: 'A valider',
    tabs_user_skills_title: 'Compétences',
    tabs_allcontents_title: 'Tous les contenus',
    tabs_draftcontents_title: 'Non soumis',
    tabs_refusedcontents_title: 'Contenus réfusés',
    tabs_acceptedcontents_title: 'Contenus acceptés',
    tabs_submittedcontents_title: 'Contenus soumis',
    tabs_generic_empty_text: 'Aucun élément',
    tabs_allcontents_empty_text: 'Aucun contenu.',
    tabs_draftcontents_empty_text: 'Aucun contenu à proposer.',
    tabs_refusedcontents_empty_text: 'Aucun contenu refusé.',
    tabs_acceptedcontents_empty_text: 'Aucun contenu accepté.',
    tabs_submittedcontents_empty_text: 'Aucun contenu soumis.',
    tabs_allproposals_label: 'Toutes',
    tabs_pendingproposals_label: 'A valider',
    tabs_refusedproposals_label: 'Réfusées',
    tabs_acceptedproposals_label: 'Acceptées',
    tabs_allproposals_title: 'Toutes les propositions',
    tabs_pendingproposals_title: 'Non validées pour le moment',
    tabs_refusedproposals_title: 'Propositions réfusées',
    tabs_acceptedproposals_title: 'Propositions acceptées',
    tabs_allproposals_empty_text: 'Aucune proposition.',
    tabs_pendingproposals_empty_text: 'Aucune proposition en attente de validation.',
    tabs_refusedproposals_empty_text: 'Aucune proposition refusée.',
    tabs_acceptedproposals_empty_text: 'Aucune proposition acceptée.',
    tabs_unpublishedversions_title: 'Versions non publiées',
    tabs_publishedversions_title: 'Versions publiées',
    tabs_archivedversions_title: 'Versions archivées',
    tabs_unpublishedversions_label: 'Non publiées',
    tabs_publishedversions_label: 'Publiées',
    tabs_archivedversions_label: 'Archivées',
    tabs_allversions_label: 'Toutes les versions',
    tabs_allversions_empty_text: 'Aucune version de contenu.',
}