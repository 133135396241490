import React from 'react';
import {connect} from 'react-redux';
import Route from './Route';
import {getScreen, isUserHavingRole} from "../../utils";
import config from '../../configs';

export const PrivateRoute = connect(({user}) => ({user}))(({user, path, screen, strict}) => {
    screen = (!user || !user['authenticated']) ? 'auth/Login' : (user.matchRequiredRole ? ((!isUserHavingRole(user, config.routePathRoles[path || '*'] || [])) ? 'auth/Denied' : screen) : 'auth/Unauthorized');
    return (
        <Route component={getScreen(screen)} path={path} strict={strict} />
    );
});

export default PrivateRoute