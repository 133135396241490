import app from './app';
import category from './category';
import content from './content';
import proposal from './proposal';
import language from './language';
import accessmode from './accessmode';
import challenge from './challenge';
import masterclass from './masterclass';
import provider from './provider';
import source from './source';
import tag from './tag';
import type from './type';
import user from './user';
import widget from './widget';
import skill from './skill';
import form from './form';
import formsubmission from './formsubmission';
import version from './version';
import admin from './admin';

export default [
    ...content,
    ...proposal,
    ...user,
    ...category,
    ...tag,
    ...type,
    ...provider,
    ...language,
    ...source,
    ...masterclass,
    ...accessmode,
    ...challenge,
    ...widget,
    ...skill,
    ...form,
    ...formsubmission,
    ...version,
    ...admin,
    ...app,
]
