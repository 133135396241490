export default {
    '/admin': {
        mode: 'admin',
        items: [
            {id: 'adminHome', path: '/admin', icon: 'admin'},
            {id: 'categories', path: '/admin/categories', icon: 'categories'},
            {id: 'tags', path: '/admin/tags', icon: 'tags'},
            {id: 'types', path: '/admin/types', icon: 'types'},
            {id: 'providers', path: '/admin/providers', icon: 'providers'},
            {id: 'languages', path: '/admin/languages', icon: 'languages'},
            {id: 'accessmodes', path: '/admin/accessmodes', icon: 'accessmodes'},
            {id: 'skills', path: '/admin/skills', icon: 'skills'},
            {id: 'users', path: '/admin/users', icon: 'people'},
            {id: 'sources', path: '/admin/sources', icon: 'sources'},
            {id: 'backHome', path: '/', icon: 'back'},
        ],
    },
    '/': {
        mode: 'default',
        items: [
            {id: 'home', path: '/', icon: 'dashboard'},
            {id: 'proposals', path: '/proposals', icon: 'proposals'},
            {id: 'contents', path: '/contents', icon: 'contents'},
            {id: 'versions', path: '/versions', icon: 'versions'},
            {id: 'challenges', path: '/challenges', icon: 'challenges'},
            {id: 'masterclasses', path: '/masterclasses', icon: 'masterclasses'},
            {id: 'widgets', path: '/widgets', icon: 'widgets'},
            {id: 'forms', path: '/forms', icon: 'forms'},
            {id: 'formSubmission', path: '/form-submissions', icon: 'forms'},
            {id: 'adminHome', path: '/admin', icon: 'admin'},
            {id: 'help', path: '/help', icon: 'help'},
        ],
    },
}